/* global M4CGlobal, MatgenGlobal, $, Pikaday, noUiSlider, wNumb, google, dataLayer */

import M4CRichTextEditor from '../../../../node_modules/@m4c/matgen/src/js/matgen-ui/components/M4CRichTextEditor.js';
import * as FORM from '../../-components/display/form-components.js';
import { RichTextForm } from '../../+auth-global/display.js';
import { v4 as UUID } from 'uuid';
//import DOMPurify from 'dompurify';
import { getMicrositeVars } from './data.js';
import { MicrositeTemplate } from './-components/MicrositeTemplate.js';
import { validateAndSetValues, prepareStudyData } from './data.js';
import {
  mainContentLoader,
  mainContentLoaderStop,
} from '../../+auth-global/display.js';
/*const answerExample = ({ example, i }) => {
  if (example.type === 'textarea') {
    return `
    <div class="carousel-item${i === 0 ? ' active' : ''}">
      <div class="example-text-slide">
        <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
        <div>${example.answer}</div>
      </div>
    </div>
    `;
  } else if (example.type === 'richtext') {
    let sanitized = false;
    try {
      const exampleAnswer = JSON.parse(example.answer);
      sanitized = DOMPurify.sanitize(exampleAnswer.html);
    } catch (e) {
      console.error(e, example.answer);
      return `
      <div class="carousel-item${i === 0 ? ' active' : ''}">
        <div class="example-text-slide">
          <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
          <div>Bad HTML</div>
        </div>
      </div>
      `;
    }
    if (sanitized) {
      return `
      <div class="carousel-item${i === 0 ? ' active' : ''}">
        <div class="example-text-slide">
          <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
          <div>${sanitized}</div>
        </div>
      </div>
      `;
    }
  }
};*/

/*
export const RichTextForm = (q) => {
  let instructions = false;
  try {
    if (q.instructions) {
      instructions = JSON.parse(q.instructions);
    }
  } catch (e) {
    instructions = false;
  }
  let cleanInstructions = false;
  if (instructions) {
    cleanInstructions = DOMPurify.sanitize(instructions.html);
  }
  if (q.examples) {
    q.examples.sort((a, b) => a.sort_order - b.sort_order);
  }
  return `
    <div id="form-container-${q.id}" style="height:100%;">
      <form
        id="rich-text-form-${q.id}"
        class="matgen-richtext-form"
        style="min-width:450px;height:100%;"
      >

//         <div class="row">
//           <div class="col">
//             <div class="form-error"></div>
//             <div class="form-success"></div>
//           </div>
//         </div>

//         <div class="rich-text-wrapper">

//           <div class="rich-text-half rich-text-editor-wrapper">

//           <div class="rich-text-left-top">
//             <h5>Consider Including:</h5>
//             <div class="question-instructions" id="rich-text-instructions-${
//               q.id
//             }">
//             ${cleanInstructions ? cleanInstructions : ''}
//             </div>
//           </div>

//             <div id="quill-container"><div id="input-rich-text-${
//               q.id
//             }" data-lpignore="true"></div></div>

//           </div>

//           <div class="rich-text-half rich-text-preview-wrapper">

//             <div class="rich-text-right">
//               <div class="rich-text-right-inner" id="rich-text-preview-${q.id}">
//                   ${
//                     q.examples
//                       ? `
//                     <h4>Examples</h4>
//                     <div id="example-carousel-${q.id}" class="carousel slide">
//                       <div class="carousel-inner">
//                         ${q.examples
//                           .map((a, i) =>
//                             answerExample({
//                               example: a,
//                               i,
//                             })
//                           )
//                           .join('')}
//                       </div>
//                       <div class="carousel-indicators">
//                           ${q.examples
//                             .map(
//                               (a, i) => `
//                             <button type="button" data-bs-target="#example-carousel-${
//                               q.id
//                             }" data-bs-slide-to="${i}" class="active" aria-current="true" aria-label="Slide ${
//                                 i + 1
//                               }">&#x25CF;</button>
//                             `
//                             )
//                             .join('')}
//                       </div>
//                       <button class="carousel-control-prev" type="button" data-bs-target="#example-carousel-${
//                         q.id
//                       }" data-bs-slide="prev">
//                         <span class="carousel-control-prev-icon" aria-hidden="true">
//                           <i class="fa-regular fa-angle-left fa-2xl"></i>
//                         </span>
//                         <span class="visually-hidden">Previous</span>
//                       </button>
//                       <button class="carousel-control-next" type="button" data-bs-target="#example-carousel-${
//                         q.id
//                       }" data-bs-slide="next">
//                         <span class="carousel-control-next-icon" aria-hidden="true">
//                           <i class="fa-regular fa-angle-right fa-2xl"></i>
//                         </span>
//                         <span class="visually-hidden">Next</span>
//                       </button>
//                     `
//                       : ''
//                   }
//                 </div>

//               </div>
//             </div>

//           </div>

//         </div>

      </form>
    </div>
  `;
};
*/

export const applicationContent = (section, area) => {
  const content = {
    select: {
      header: {
        original: ``,
        edit: ``,
      },
    },
    data: {
      websiteHeader: {
        original: `
          <h1 class="color--blue hero__heading">Edit Website Content</h1>
        `,
      },
      header: {
        original: `
          <h1 class="color--blue hero__heading">
           Populate Your Materials with<br> Information About Your Study
          </h1>
          <p>Now that you have selected materials to promote your study, answer the questions and enter in the requested information, below. This process allows you to enter study information once and automatically apply it to each of the materials you selected in the previous step. Your responses to this questionnaire can be used in any study-specific materials you create through OutreachPro – now or in the future. Note: Answers may be edited for the study or for individual materials after this initial entry.</p>
          `,
        edit: `
          <h1 class="color--blue hero__heading">
           Populate Your Materials with<br> Information About Your Study
          </h1>
          <p>Edit your study data below. This process allows you to edit study information once and automatically apply it to each of the materials you selected for your study. Your responses to this questionnaire can be used in any study-specific materials you create through OutreachPro – now or in the future. Note: Answers may be edited for the study or for individual materials anytime.</p>
          `,
      },
    },
    customize: {
      header: {
        original: `
          <h1 class="color--green hero__heading">
            Great job!
          </h1>
          <p>You’ve taken the first step to create study materials. Next step: Customize.</p>
          `,
      },
    },
    confirm: {
      header: {
        original: `
          <h1 class="color--green hero__heading">
            Great job!
          </h1>
          <p>You’ve taken the first step to create study materials. Next step: Customize.</p>
          `,
        material: `
          <h1 class="color--green hero__heading">
            Material saved!
          </h1>
          <p>Continue to customize or download what you need.</p>
          `,
        edit: `
        <h1 class="color--green hero__heading">
          Your Materials for Study: ${
            MatgenGlobal.currentStudy ? MatgenGlobal.currentStudy.name : ''
          }
        </h1>
        <p>Continue to customize or download what you need.</p>
        `,
      },
    },
  };
  if (MatgenGlobal.opener === 'editor' && section === 'confirm') {
    return content[section][area].material;
  }
  if (MatgenGlobal.opener !== 'my-materials') {
    return content[section][area].original;
  } else {
    return content[section][area].edit;
  }
};

const isJson = (item) => {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
};

export const displayQuestion = (q) => {
  let data;
  let html = '';
  let init = false;
  let response = false;
  let newAnswers;
  let newAnswer;
  let inputHtml;
  let addName = 'item';
  let answers = q.answers;

  //console.log(q.id, answers, q.answers);

  if (q.answers.answer) {
    html += FORM.questionHeader(q);
    data = {};
    data.type = 'text';
    data.label = q.text;
    data.sub_text = q.sub_text;
    data.insert_sub = q.insert_sub;
    data.usedAsHeading = q.insert_sub;
    data.id = q.id;
    if (answers.answer) {
      data.value = answers.answer;
    }
    html += FORM.textAreaComponent(data);

    init = () => {
      $(`#${q.id}`).trigger('input');
    };

    return { html, init };
  }

  switch (q.component) {
    default:
      throw new Error(`Invalid component type: ${q.component}`);
    case 'richtext':
      html += FORM.questionHeader(q);
      html += RichTextForm(q);
      init = () => {
        MatgenGlobal.RichtextExitSelector = () => {
          setTimeout(() => {
            $(
              '#collapse900f8c49-b2c9-4d50-b1a5-c1535d734815 button.ql-bold'
            ).focus();
          }, 1000);
        };

        new M4CRichTextEditor({
          id: q.id,
          /*changeHandler: (e) => {
            console.log('RTE', q, JSON.stringify(e));
          },*/
        });
        if (MatgenGlobal.currentStudy.answers) {
          const answer = MatgenGlobal.currentStudy.answers.find(
            (a) => a.question_id === q.id
          );
          if (answer && answer.answer) {
            try {
              const obj = JSON.parse(answer.answer);
              M4CGlobal.quill[q.id].setContents(obj.data);
            } catch (e) {
              console.error('Bad anser JSON');
            }
          }
        }
      };

      return { html, init };
    case 'date-range':
      html += FORM.questionHeader(q);
      html += FORM.dateRangeComponent(q);
      init = () => {
        new Pikaday({ field: document.getElementById(`${q.id}-start`) });
        new Pikaday({ field: document.getElementById(`${q.id}-end`) });
      };
      return { html, init };
    case 'img-select-single':
      html += FORM.questionHeader(q);
      response = FORM.imageSelectComponent(
        q,
        'https://m4c-matgen-microsites-new.s3.amazonaws.com/nia/template-001/assets/img/'
      );
      html += response.html;
      init = response.init;
      return { html, init };
    case 'list-faq':
      html += FORM.questionHeader(q);
      html += FORM.faqListComponent(q);
      init = () => {
        $(`#${q.id}`).sortable({
          handle: '.faq-sort',
          onEnd: () => {
            $(`#${q.id} li`).each((i, el) => {
              const id = $(el).attr('id').replace('-li', '');
              q.answers.find((a) => a.id === id).sort_order = i;
              $(el)
                .find('.faq-sort-number')
                .text(i + 1);
            });
            q.answers.sort((a, b) => a.sort_order - b.sort_order);
          },
        });
      };
      return { html, init };
    case 'list-multi-input':
      html += FORM.questionHeader(q);

      newAnswers = {
        instanceId: UUID(),
        answers: [],
      };
      if (!q.userAnswers) {
        for (let i = 0; i < q.answers.length; i++) {
          newAnswer = {
            text: q.answers[i].text,
            sub_text: q.answers[i].sub_text,
            id: q.answers[i].id,
            sort_order: q.answers[i].sort_order,
          };

          if (q.userAnswers && q.userAnswers[i]) {
            newAnswer.userAnswer = q.userAnswers[i];
          }
          newAnswers.answers.push(newAnswer);
        }
        q.userAnswers = [newAnswers];
        response = FORM.multiInputComponent(q, newAnswers.instanceId, q.id);
        inputHtml = response.html;
      } else {
        inputHtml = '';
        for (let i = 0; i < q.userAnswers.length; i++) {
          response = FORM.addMultiInputListItem(q, q.id, q.userAnswers[i]);
          inputHtml += response.html;
        }
      }
      if (q.answers.length > 0) {
        html += `
          <div id="${q.id}-wrapper">
            ${inputHtml}
          </div>
        `;
      }
      if (q.text.includes('located')) {
        addName = 'location';
      }
      if (q.text.includes('investigators')) {
        addName = 'investigator';
      }
      if (q.text.includes('contact')) {
        addName = 'contact';
      }
      html += `<button id="add-multi-input-${q.id}" data-question-id="${q.id}" tabindex="0" type="button" class="btn btn-primary add-multi-input multi-add-btn">+ Add ${addName}</button>`;
      init = response.init;
      return { html, init };
    case 'multi-input':
      html += FORM.questionHeader(q);
      response = FORM.multiInputComponent(q, q.id, q.id, true);
      html += response.html;
      html += `<button id="add-multi-input-single-${q.id}" data-question-id="${q.id}" tabindex="0" type="button" class="btn btn-primary add-multi-input-single multi-add-btn">+ Add ${addName}</button>`;
      init = () => {
        if ($(`#${q.id}`).length > 0) {
          $(`#add-multi-input-single-${q.id}`).hide();
        } else {
          $(`#add-multi-input-single-${q.id}`).show();
        }
      };
      return { html, init };
    case 'range':
      html += FORM.questionHeader(q);
      html += FORM.rangeComponent(q);
      init = () => {
        noUiSlider.create(document.getElementById(q.id), {
          start: [15, 90],
          connect: true,
          range: {
            min: 18,
            max: 90,
          },
          format: wNumb({
            decimals: 0,
          }),
          step: 1,
          tooltips: [
            wNumb({ decimals: 0 /*suffix: ' yrs'*/ }),
            wNumb({
              decimals: 0,
              //suffix: ' yrs',
              edit: function (a) {
                return parseInt(a) >= 90 ? a.replace(' yrs', '+ yrs') : a;
              },
            }),
          ],
        });
        if (answers[0].userAnswer) {
          document.getElementById(q.id).noUiSlider.set(answers[0].userAnswer);
        }
      };
      return { html, init };
    case 'select-multiple':
      html += FORM.questionHeader(q);
      html += `<label for="${q.id}" class="sr-only form-label required">${q.text}</label>`;
      html += FORM.buttonSelectComponent(q);
      return { html };
    case 'select-single':
      html += FORM.questionHeader(q);
      html += `<label for="${q.id}" class="sr-only form-label required">${q.text}</label>`;
      html += FORM.buttonSelectComponent(q);
      return { html };
    case 'list-select-single':
      html += FORM.questionHeader(q);
      response = FORM.listSingleSelectComponent(q);
      html += response.html;
      return { html };
    case 'card-select-single':
      html += FORM.questionHeader(q);
      html += FORM.cardSingleSelectComponent(q.answers, q.id);
      return { html };
    case 'box-select-single':
      html += FORM.questionHeader(q);
      html += FORM.boxSingleSelectComponent(q.answers, q.id);
      return { html };
    case 'text':
      html += FORM.questionHeader(q);
      data = answers.text ? JSON.parse(answers.text) : false;

      if (!data) {
        answers = answers[0];
        data =
          answers && answers.text
            ? isJson(answers.text)
              ? JSON.parse(answers.text)
              : { type: 'text', label: answers.text }
            : {};
      }

      data.type = 'text';
      data.id = answers.id;
      data.label = q.text;
      if (answers.userAnswer && answers.userAnswer != '') {
        data.value = answers.userAnswer;
      }
      html += FORM.textComponent(data);
      return { html };
    case 'textarea':
      html += FORM.questionHeader(q);
      data = JSON.parse(answers[0].text);
      data.type = 'text';
      data.label = q.text;
      data.sub_text = q.sub_text;
      data.insert_sub = q.insert_sub;
      data.id = answers[0].id;
      if (answers[0].userAnswer && answers[0].userAnswer != '') {
        data.value = answers[0].userAnswer;
      }
      html += FORM.textAreaComponent(data);

      init = () => {
        $(`#${answers[0].id}`).trigger('input');
      };

      if (data.optional) {
        html = FORM.optionalWrapper(data, html);
        init = () => {
          init();
          $(`[name="${data.id}"]`).on('click keypress', (e) => {
            if (MatgenGlobal.UI.a11yClick(e) === true) {
              if ($(e.target).val() === 'yes') {
                $(`#optional-wrapper-${data.id}`).show();
              } else {
                $(`#optional-wrapper-${data.id}`).hide();
              }
            }
          });
        };
      }

      return { html, init };
    case 'upload-single':
      html += FORM.questionHeader(q);
      response = FORM.singleUploadComponent(q);
      html += response.html;
      init = response.init;
      return { html, init };
  }
};

export const studyDataContainer = () => {
  return `
  <div class="d-flex populate" id="tabfocus">
    <div class="container hero-create-materials" id="hero-create-materials">
      <div class="row">
        <div class="col-sm-12">
          ${applicationContent('data', 'websiteHeader')}
        </div>
      </div>
    </div>
  </div>

  <div id="website-data-container">
    <div class="populate-responses" id="populate-responses">
      <div class="" id="website-data-input">
        <div id="website-data-wrapper">
          <div class="row tab-row">
            <div class="col-lg-2 tab-wrapper">
              <div id="tabs">
              </div>
            </div>

            <div class="col-lg-10" id="section-wrapper">
            <form id="microsite-form">
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="website-form-controls">
    <div class="btn-grp">

    <div class="btn-wrp"><span id="website-management-step2-exit" class="button--blue button--hollow" aria-labelledby="website-form-controls" tabindex="0">Exit</span></div>
    <div class="btn-wrp"><span id="website-data-preview" class="button--blue button--hollow" aria-labelledby="website-form-controls" tabindex="0">Preview</span></div>
    <div class="btn-wrp"><span class="button--blue save-section" aria-labelledby="website-form-controls" tabindex="0">Save In Progress</span></div>
    <div class="btn-wrp"><span class="button--green complete-form" aria-labelledby="website-form-controls" tabindex="0">Complete</span></div>

  </div>
</div>
  `;
};

export const collectDataUI = ({
  target = MatgenGlobal.MainContentContainer,
  buttons = true,
  scope,
} = {}) => {
  const timerStart = Date.now();
  MatgenGlobal.formTimer = timerStart;

  const inits = [];
  let question_keys = [];
  $(target).empty().append(studyDataContainer(buttons));

  if (MatgenGlobal.currentStudy && MatgenGlobal.currentStudy.questions) {
    try {
      question_keys = Object.keys(MatgenGlobal.currentStudy.questions);

      for (let i = 0; i < question_keys.length; i++) {
        const answerRecord = MatgenGlobal.currentStudy.answers
          ? MatgenGlobal.currentStudy.answers.find(
              (a) => a.question_id === question_keys[i]
            )
          : false;

        if (answerRecord) {
          try {
            const answer = JSON.parse(answerRecord.answer);
            switch (
              MatgenGlobal.currentStudy.questions[question_keys[i]].component
            ) {
              default:
                MatgenGlobal.currentStudy.questions[question_keys[i]].answers =
                  answer;
                break;
              case 'list-multi-input':
              case 'list-faq':
                MatgenGlobal.currentStudy.questions[
                  question_keys[i]
                ].userAnswers = answer;
                break;
            }
          } catch (e) {
            console.error(e);
            console.log('BAD JSON:', answerRecord);
          }
        }
        //console.log(answer, MatgenGlobal.currentStudy.questions[question_keys[i]]);
      }

      if (MatgenGlobal.currentStudy.answers.length === 0) {
        dataLayer.push({
          event: 'create-microsite',
        });
      }

      //console.log(MatgenGlobal.currentStudy.questions);
    } catch (e) {
      console.error(e);
    }

    question_keys = Object.keys(MatgenGlobal.currentStudy.questions);
  } else {
    if (!MatgenGlobal.currentStudy) {
      return false;
    } else if (!MatgenGlobal.currentStudy.questions) {
      //window.location.href = `/study-materials.html?study_id=${MatgenGlobal.currentStudy.id}&navtool=${MatgenGlobal.navtool}`;
      scope.error();
      /*$('#website-data').append(
        $(
          `<p style="text-align:center;font-weight:bold;color:B30000;">You've selected material that does not require this step. Click "Continue" to proceed and customize your material.</p>`
        )
      );
      $('#study-data-save').hide();
      MatgenGlobal.UI.stopLoading();*/
      return false;
    }
  }

  /*if (question_keys.length === 0) {
    window.location.href = `/study-materials.html?study_id=${MatgenGlobal.currentStudy.id}&navtool=${MatgenGlobal.navtool}`;
    return false;
  }*/

  const section_types = [
    'website_details',
    'study_logo',
    'study_expectations',
    'study_faq',
    'study_info',
    'study_hero',
    'study_participants',
  ];

  const section_types_ids = [
    { name: 'Website Details', id: '16ae7ea9-1fb8-4bf9-831a-8a81e986431e' },
    { name: 'Eligibility', id: '6ea180b7-a779-4e95-9eeb-343b054533ee' },
    { name: 'About the Study', id: '7a08691d-4105-4388-a81e-e4912e8530ea' },
    { name: 'Risks and Benefits', id: '88033b98-f4e6-4dc7-83fe-8010a7208d4f' },
    { name: 'Common Questions', id: 'aade21c4-2a0f-4537-b995-9629d8477660' },
    { name: 'Study Location', id: 'b5916ac0-c72c-4e45-931a-3777ca85979b' },
    { name: 'Investigators', id: 'c775adcf-4fd1-4fe5-aaaf-09dea4ffd511' },
  ];

  const sections = {};

  for (let i = 0; i < section_types.length; i++) {
    // const qtmp = {};
    const ktmp = Object.keys(MatgenGlobal.currentStudy.questions);

    for (let k = 0; k < ktmp.length; k++) {
      if (!sections[section_types[i]]) {
        sections[section_types[i]] = {};
      }
      if (
        MatgenGlobal.currentStudy.questions[ktmp[k]].section_type ===
        section_types[i]
      ) {
        sections[section_types[i]][ktmp[k]] =
          MatgenGlobal.currentStudy.questions[ktmp[k]];
      }
    }
  }
  for (let i = 0; i < section_types.length; i++) {
    //Create Tab Sidebar
    const tabs = $('#tabs');
    const sectionWrapper = $('#microsite-form');

    tabs.append(
      `<span class="tab ${
        i == 0 ? `active` : ``
      }" data-div-id="#tab-section-${i}" data-section-id="${
        section_types_ids[i].id
      }"><i class="title">${
        section_types_ids[i].name
      }</i> <i class="warning">0</i></span>`
    );

    sectionWrapper.append(`<div class="tab-section ${
      i == 0 ? `active` : ``
    }" id="tab-section-${i}" data-section-id="${section_types_ids[i].id}">
      <div class="details-heading">
        <h2>${section_types_ids[i].name}</h2>
        <p>An asterisk (<span style="color: red;">*</span>) indicates a required field</p>
      </div>

      <div id="website-data-${i}"></div>

      <div class="btn-grp">
        <div class="btn-wrp">${
          i != 0
            ? `<span class="button--blue button--hollow prevSection" tabindex="0">&#x3c; Previous Section</span>`
            : ``
        }</div>
        <div class="btn-wrp"></div>
        <div class="btn-wrp">${
          i != section_types.length - 1
            ? `<span class="button--blue button--hollow nextSection" tabindex="0">Next Section &#x3e;</span>`
            : ``
        }</div>
      </div>
    </div>`);
    ////

    const question_keys = Object.keys(sections[section_types[i]]);
    // Do display logic here

    const form = $(
      `<div id="microsite-form-${i}" class="microsite-form"></div>`
    );
    $(`#website-data-${i}`).append(form);

    for (let i = 0; i < question_keys.length; i++) {
      const answers =
        MatgenGlobal.currentStudy.questions[question_keys[i]].answers;

      if (answers && !answers.override && !answers.html) {
        answers.sort((a, b) => a.sort_order - b.sort_order);
      }

      const q = displayQuestion(
        MatgenGlobal.currentStudy.questions[question_keys[i]]
      );
      const box = $(`
        <div id="collapse${
          MatgenGlobal.currentStudy.questions[question_keys[i]].id
        }" data-card-id="${
        MatgenGlobal.currentStudy.questions[question_keys[i]].id
      }" class="blue-box ${
        MatgenGlobal.currentStudy.questions[question_keys[i]].component
      }"></div>
      `);
      box.append(
        FORM.questionWrapper(
          q.html,
          MatgenGlobal.currentStudy.questions[question_keys[i]].component ===
            'list-select-single'
        )
      );
      if (q.init && typeof q.init === 'function') {
        inits.push(q.init);
      }
      form.append(box);
    }
  }

  for (let i = 0; i < inits.length; i++) {
    inits[i]();
  }

  $('[data-toggle="tooltip"]').tooltip();

  MatgenGlobal.UI.stopLoading();
};

export const reportFormErrors = (formId, questionIds) => {
  $($(`#${formId}`)[0])
    .find(':invalid')
    .each((i, el) => {
      //$(el).parent().addClass(['matgen-form-error', 'matgen-form-wrapper']);
      $(el)
        .closest('.blue-box')
        .css('border', '1px solid #f00')
        .addClass('matgen-form-error');
      $(el).addClass('matgen-form-error');
      $(el).parent().find('.char-limit').addClass('matgen-form-error');
      $(el).parent().find('.form-label').addClass('matgen-form-error');
    });

  questionIds.forEach((q) => {
    //$(`#${q}`).addClass(['matgen-form-error', 'matgen-form-wrapper']);
    //$(`#${q}`).prevAll('.form-label').addClass('matgen-form-error');
    $(`#${q}`)
      .closest('.blue-box')
      .css('border', '1px solid #f00')
      .addClass('matgen-form-error');
    /*$(`#${q}`)
      .prevAll('.form-label')
      .closest('.blue-box')
      .css('border', '1px solid #f00')
      .addClass('matgen-form-error');*/
    /*$(`#optional-wrapper-${q}`).addClass([
      'matgen-form-error',
      'matgen-form-wrapper',
    ]);*/

    //todo
  });

  $('.tab').each(function (i) {
    if ($(`#tab-section-${i} .matgen-form-error.blue-box`).length) {
      $(this)
        .children('.warning')
        .html($(`#tab-section-${i} .matgen-form-error.blue-box`).length);

      $(this).children('.warning').addClass('active');
    }
  });
};

export const clearFormErrors = (el) => {
  $(el)
    .closest('.blue-box')
    .css('border', 'none')
    .removeClass('matgen-form-error');
  $(el).removeClass('matgen-form-error');
  $(el).parent().removeClass('matgen-form-error');
  $(el).parent().find('.char-limit').removeClass('matgen-form-error');
  $(el).parent().find('.form-label').removeClass('matgen-form-error');

  $(el).parent().parent().removeClass('matgen-form-error');
  $(el)
    .parent()
    .parent()
    .parent()
    .find('.char-limit')
    .removeClass('matgen-form-error');
  $(el)
    .parent()
    .parent()
    .parent()
    .find('.form-label')
    .removeClass('matgen-form-error');
};

const initAuto = (target) => {
  MatgenGlobal.autocomplete = new google.maps.places.Autocomplete(target, {
    fields: ['address_components', 'formatted_address', 'geometry', 'name'],
    strictBounds: false,
    componentRestrictions: { country: 'us' },
    //types: ['establishment'],
  });
  MatgenGlobal.autocomplete.addListener('place_changed', () =>
    handleAutocompleteChange(target)
  );
};

const handleAutocompleteChange = (target) => {
  const place = MatgenGlobal.autocomplete.getPlace();
  const inputs = $(target).parent().parent().parent().find('.location-inputs');
  const number = place.address_components.find((a) =>
    a.types.includes('street_number')
  );
  const subpremise = place.address_components.find((a) =>
    a.types.includes('subpremise')
  );
  const state = place.address_components.find(
    (a) =>
      a.types.includes('political') &&
      a.types.includes('administrative_area_level_1')
  );
  const city = place.address_components.find(
    (a) => a.types.includes('political') && a.types.includes('locality')
  );
  const route = place.address_components.find((a) => a.types.includes('route'));
  inputs.find('[placeholder="Address Line 1"]').val(`
  ${number.long_name}
  ${route.long_name}
  `);
  inputs.find('select').val(state.long_name);
  inputs.find('[placeholder="City"]').val(city.long_name);
  if (subpremise) {
    inputs.find('[placeholder="Address Line 2"]').val(subpremise.long_name);
  }
};

export const initLocationAutoComplete = (id) => {
  if (window.googleMapsPlacesReady) {
    const target = $(`#${id}`)[0];
    initAuto(target);
  } else if (!window.googleMapsPlacesReadyEventAttached) {
    window.googleMapsPlacesReadyEventAttached = true;
    $(document).on('google-maps-places-ready', () => {
      console.log('google-maps-places-ready');
      const target = $(`#${id}`)[0];
      initAuto(target);
    });
  } else {
    console.error('HOW YOU GET HERE?');
  }
};

export const getMicrositePreview = async (id, showAccordions = false) => {
  /*const template_id = id ? id : MatgenGlobal.currentStudy.micrositeTemplateIds[0];
  console.error('getMicrositePreview:', id);
  const micrositeRecord = await MatgenGlobal.Data.getMicrosite(
    MatgenGlobal.currentStudy.microsite.id
  );*/
  const study_id = MatgenGlobal.getQueryParam('study_id');
  let valid = true;
  if (window.location.href.includes('website-data.html')) {
    valid = validateAndSetValues(false);
  }

  if (valid) {
    if (window.location.href.includes('website-data.html')) {
      const { inserts } = await prepareStudyData();

      const answers = [];
      for (let i = 0; i < inserts.length; i++) {
        switch (MatgenGlobal.currentStudy.questions[inserts[i].id].component) {
          default:
            answers.push({
              id: UUID(),
              user_folder_id: study_id,
              question_id: inserts[i].id,
              answer: JSON.stringify(
                MatgenGlobal.currentStudy.questions[inserts[i].id].answers
              ),
              material_id: 'website',
            });
            break;
          case 'richtext':
            answers.push({
              id: UUID(),
              user_folder_id: study_id,
              question_id: inserts[i].id,
              answer: JSON.stringify({
                html: M4CGlobal.quill[inserts[i].id].root.innerHTML,
                plain: M4CGlobal.quill[inserts[i].id].getText(),
                data: M4CGlobal.quill[inserts[i].id].getContents(),
              }),
              material_id: 'website',
            });
            break;
          case 'list-multi-input':
          case 'upload-single':
            answers.push({
              id: UUID(),
              user_folder_id: study_id,
              question_id: inserts[i].id,
              answer: JSON.stringify(
                MatgenGlobal.currentStudy.questions[inserts[i].id].userAnswers
              ),
              material_id: 'website',
            });
            break;
          case 'list-faq':
            answers.push({
              id: UUID(),
              user_folder_id: study_id,
              question_id: '99120986-a88a-46c1-ae87-2af83030740e',
              answer: MatgenGlobal.currentStudy.questions[inserts[i].id]
                .userAnswers
                ? JSON.stringify(
                    MatgenGlobal.currentStudy.questions[inserts[i].id]
                      .userAnswers
                  )
                : JSON.stringify(
                    MatgenGlobal.currentStudy.questions[inserts[i].id].answers
                  ),
              material_id: 'website',
            });
            break;
        }
      }
      MatgenGlobal.currentStudy.answers = answers;
    }

    const microsite_vars = getMicrositeVars(
      parseInt(id.charAt(id.length - 1)) - 1
    );
    microsite_vars.showAccordions = showAccordions;
    let text;
    try {
      text = await MicrositeTemplate(microsite_vars);
    } catch (e) {
      console.error(e);
    }

    MatgenGlobal.microsite_vars = microsite_vars;
    MatgenGlobal.microsite = text;
    return text;
  }
  return false;
};

export const showMicrositePreview = async ({
  id,
  target = '#microsite-preview-modal .modal-body',
  showAccordions = false,
} = {}) => {
  mainContentLoader({
    showMessage: true,
    target: '#microsite-preview-modal .modal-body',
  });
  $('#loader-message').text('Loading website data...');
  $('#main-loader-wrapper').css('background', '#ffffffc0');
  //console.log(JSON.stringify(sections));
  //console.error('showMicrositePreview:', id, target, showAccordions);
  const text = await getMicrositePreview(id, showAccordions);
  if (text) {
    const ifr = document.createElement('iframe');
    ifr.setAttribute('frameborder', '0');
    ifr.setAttribute('id', 'iframeOutput');
    ifr.setAttribute('title', 'Site Preview');
    $(target)[0].innerHTML = '';
    $(target)[0].appendChild(ifr);
    const ifrw = ifr.contentWindow
      ? ifr.contentWindow
      : ifr.contentDocument.document
      ? ifr.contentDocument.document
      : ifr.contentDocument;
    ifrw.document.open();
    ifrw.document.write(text);
    ifrw.document.close();
    mainContentLoaderStop();
    $('#main-loader-wrapper').css('background', 'initial');
  }
  /*console.log(
    `<!doctype html><html lang="en">${MatgenGlobal.microsite}</html>`
  );*/
};

export const showMicrositePreviewModal = (id) => {
  if (!id) {
    id = '00000000-0000-0000-0000-000000000001';
  }

  let buttons = [];
  if (window.location.href.includes('website-management.html')) {
    buttons = [
      {
        id: `website-template-cancel`,
        classname: '',
        label: 'Cancel',
      },
      {
        id: `website-template-continue`,
        attributes: [`data-template-id="${id}"`],
        classname: 'primary',
        label: 'Save Template',
      },
    ];
  }

  MatgenGlobal.M4CModal.show({
    id: 'microsite-preview-modal',
    content: '',
    footer: true,
    buttons,
  });

  //showMicrositePreview({ id });
  let tIndex = parseInt(id.charAt(id.length - 1));
  if (!tIndex) {
    tIndex = 1;
  }
  if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
    $('#microsite-preview-modal .modal-footer').prepend(
      $(`
      <div class="preview-btns-group">
        <div class="radio-group">
          <input type="radio" id="microsite-preview-modal-v0-button" name="color" value="blue" ${
            tIndex === 1 ? 'checked' : ''
          }>
          <label for="microsite-preview-modal-v0-button">Blue</label>
        </div>

        <div class="radio-group">
          <input type="radio" id="microsite-preview-modal-v1-button" name="color" value="purple" ${
            tIndex === 2 ? 'checked' : ''
          }>
          <label for="microsite-preview-modal-v1-button">Purple</label>
        </div>

        <div class="radio-group">
          <input type="radio" id="microsite-preview-modal-v2-button" name="color" value="green" ${
            tIndex === 3 ? 'checked' : ''
          }>
          <label for="microsite-preview-modal-v2-button">Green</label>
        </div>
      </div>
    `)
    );
    $('#microsite-preview-modal .modal-footer').addClass('theme-select');
  }

  $(`#microsite-preview-modal-v0-button`).off('click keypress');
  $(`#microsite-preview-modal-v0-button`).on('click keypress', (e) => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      //$(`#microsite-preview-modal`).modal('toggle');
      showMicrositePreview({
        id: '00000000-0000-0000-0000-000000000001',
      });
      $('.microsite-template-option').removeClass('btn-warning');
      $('.microsite-template-option').addClass('btn-secondary');
      $('.button--blue-darker-hollow.active').removeClass('active');
      $(`#microsite-preview-modal-v0-button`)
        //.removeClass('btn-warning')
        //.addClass('btn-warning')
        .addClass('active');
    }
  });

  $(`#microsite-preview-modal-v1-button`).off('click keypress');
  $(`#microsite-preview-modal-v1-button`).on('click keypress', (e) => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      //$(`#microsite-preview-modal`).modal('toggle');
      showMicrositePreview({
        id: '00000000-0000-0000-0000-000000000002',
      });
      $('.microsite-template-option').removeClass('btn-warning');
      $('.microsite-template-option').addClass('btn-secondary');
      $('.button--blue-darker-hollow.active').removeClass('active');
      $(`#microsite-preview-modal-v1-button`)
        //.removeClass('btn-warning')
        //.addClass('btn-warning')
        .addClass('active');
    }
  });

  $(`#microsite-preview-modal-v2-button`).off('click keypress');
  $(`#microsite-preview-modal-v2-button`).on('click keypress', (e) => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      //$(`#microsite-preview-modal`).modal('dispose');
      showMicrositePreview({
        id: '00000000-0000-0000-0000-000000000003',
      });
      $('.microsite-template-option').removeClass('btn-warning');
      $('.microsite-template-option').addClass('btn-secondary');
      $('.button--blue-darker-hollow.active').removeClass('active');
      $(`#microsite-preview-modal-v2-button`)
        //.removeClass('btn-warning')
        //.addClass('btn-warning')
        .addClass('active');
    }
  });

  showMicrositePreview({ id });
};
